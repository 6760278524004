var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: "Edit publisher fee" },
    on: { cancel: _vm.onCancel, submit: _vm.onSubmit },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "content-height" },
              [
                _c(
                  "form-row",
                  [
                    _c(
                      "sun-label-group",
                      { staticClass: "w-full", attrs: { text: "Publisher" } },
                      [
                        _c("asterix-async-select", {
                          attrs: {
                            value: _vm.publisherFee,
                            name: "publisher-selector",
                            service: _vm.getApiService(),
                            disabled: !!_vm.value,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.saveValue($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "form-row",
                  [
                    _vm.publisherFee
                      ? _c(
                          "sun-label-group",
                          {
                            staticClass: "w-full",
                            attrs: { text: "Custom tech fee (%)" },
                          },
                          [
                            _c("asterix-input", {
                              key: _vm.publisherFee.id,
                              attrs: {
                                type: "number",
                                min: 0,
                                max: 100,
                                required: "required",
                                placeholder: "5",
                                "auto-validate": "",
                              },
                              model: {
                                value: _vm.publisherFee.techFee,
                                callback: function ($$v) {
                                  _vm.$set(_vm.publisherFee, "techFee", $$v)
                                },
                                expression: "publisherFee.techFee",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [_c("asterix-footer-modal", { on: { cancel: _vm.onCancel } })]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }