<template>
  <asterix-form-modal title="Edit publisher fee" @cancel="onCancel" @submit="onSubmit">
    <template #content>
      <div class="content-height">
        <form-row>
          <sun-label-group class="w-full" text="Publisher">
            <asterix-async-select
              :value="publisherFee"
              name="publisher-selector"
              :service="getApiService()"
              :disabled="!!value"
              @change="saveValue($event)"
            />
          </sun-label-group>
        </form-row>
        <form-row>
          <sun-label-group v-if="publisherFee" class="w-full" text="Custom tech fee (%)">
            <asterix-input
              :key="publisherFee.id"
              v-model="publisherFee.techFee"
              type="number"
              :min="0"
              :max="100"
              required="required"
              placeholder="5"
              auto-validate
            />
          </sun-label-group>
        </form-row>
      </div>
    </template>
    <template #footer>
      <asterix-footer-modal @cancel="onCancel" />
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixFooterModal from '@/components/molecules/shared/AsterixFooterModal';
import { getPublishers } from '@/services/modules/ecommerce/publisher';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import { deepClone } from '@/utils/deepClone';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import AsterixInput from '@/components/atoms/AsterixInput';

export default {
  name: 'PublisherTechFeeModal',
  components: {
    AsterixFooterModal,
    FormRow,
    AsterixAsyncSelect,
    AsterixFormModal,
    AsterixInput,
  },
  props: {
    /** @type Publisher */
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      publisherFee: null,
    };
  },
  computed: {
    ...mapGetters({ activeClient: ACTIVE_CLIENT }),
  },
  watch: {
    value(newValue) {
      this.publisherFee = deepClone(newValue);
    },
  },
  async created() {
    this.publisherFee = deepClone(this.value);
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    onSubmit({ valid }) {
      if (valid) {
        this.$emit('update', this.publisherFee);
      }
    },
    getApiService() {
      return (params = new QueryParamsBuilder()) => {
        return getPublishers(params, this.activeClient.id);
      };
    },
    onCancel() {
      this.$emit('cancel');
    },
    saveValue(event) {
      this.publisherFee = event.items;
    },
  },
};
</script>

<style scoped>
.content-height {
  height: 25vh;
}
</style>
